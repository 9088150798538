@font-face {
  font-family: 'Segoe UI';
  src: url(../public/fonts/segoeui.ttf);
}


@font-face {
  font-family: 'Segoeuib';
  src: url(../public/fonts/segoeuib.ttf);
}
body,
p,
a,
button,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Segoe UI';
}

.fs {
  font-family: 'Segoe UI';
}

.fsb {
  font-family: 'Segoeuib';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pointer {
  cursor: pointer;
}
.active-link {
  color: #ed148b;
}

.nav-link, a {
  font-family: 'Segoe UI' !important;
}

p{
  font-family: 'Segoe UI' !important;
}
header.header  {
  background-color: #000000ba;
  position: fixed;
}
.icon-bar {
  position: fixed;
  bottom: 50%;
  z-index: 1;
  background-color: #25D366  ;
  width: 50px !important;
  height: 50px !important;
  right: 2%;
  border-radius: 10px;
}


.icon-bar a {
  display: block;
  text-align: center;
  padding: 5px;
  transition: all 0.3s ease;
  color: 25D366 !important;
  font-size: 20px;
}

.icon-bar a:hover {
  /* background-color: #25D366;
  border-radius: 10px; */
}