.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.clc-title {
	font-size: 24px;
	font-weight: 500;
	margin-top: 10px;
}

.clc {
	letter-spacing: 0px !important;
	border: 1px solid black;
}

.input-box {
	border: 1px solid black !important;
	border-radius: 5px;
	height: 50px;
}

.card {
	padding: 50px;
}

.clc-btn:hover{
	background-color: #ed148b;
	opacity: 0.5;
}

.clc-btn {
	background-color: #ed148b;
	color: #fff;
	cursor: pointer;
	border: none;
	display: inline-block;
	font-size: 14px;
	letter-spacing: 3px;
	line-height: 14px;
	overflow: hidden;
	padding: 20px 41px;
	position: relative;
	text-transform: uppercase;
	transition: background .3s, color .3s;
}

.card-text {
	color: black;
	text-align: center;
	font-size: 15px;
}

.form-control :disabled{
	background-color: #ed148b !important;
	color: white;
}

/* services-block-eight */

.service-block-eight {
	position: relative;
	margin-bottom: 40px;
}

.service-block-eight .inner-box {
	position: relative;
	overflow: hidden;
}

.service-block-eight .inner-box .image-box {
	position: relative;
}

.service-block-eight .inner-box .image-box img {
	position: relative;
	width: 100%;
	display: block;
	height: 250px;
}

.service-block-eight .inner-box .image-box .caption {
	position: absolute;
	width: 100%;
	left: 0px;
	bottom: 0px;
	color: #ffffff;
	display: block;
	font-size: 18px;
	padding: 16px 0px;
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	background-color: #ed148b;
	transition: all 0.8s ease;
	-moz-transition: all 0.8s ease;
	-webkit-transition: all 0.8s ease;
	-ms-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
}

.service-block-eight .inner-box .image-box .overlay-box {
	position: absolute;
	left: 0px;
	bottom: -250px;
	padding: 40px 35px;
	width: 100%;
	text-align: center;
	background-color: #ed148b;
	transition: all 0.8s ease;
	-moz-transition: all 0.8s ease;
	-webkit-transition: all 0.8s ease;
	-ms-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
}

.service-block-eight .inner-box .image-box .overlay-box h3 {
	position: relative;
	font-size: 18px;
	color: #ffffff;
	margin-bottom: 12px;
	font-weight: 700;
}

.service-block-eight .inner-box .image-box .overlay-box .text {
	position: relative;
	font-size: 16px;
	color: #ffffff;
	margin-bottom: 15px;
	line-height: 1.6em;
}

.service-block-eight .inner-box .image-box .overlay-box .read-more {
	position: relative;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
}

.service-block-eight .inner-box .image-box:hover .caption {
	bottom: -60px;
}

.service-block-eight .inner-box .image-box:hover .overlay-box {
	bottom: 0px;
}

.bg-theme {
	background: #ed148b !important;
	color: #fff;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	height: 45px;
	color: #212529;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0;
}

.form-control input {
	border: none;
	border-bottom: 1px solid #ced4da;
}

.ul {
	list-style: none;
}

.ul .li::before {
	content: "\2022";
	color: #f1c40f;
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

.default-portfolio-item {
	position: relative;
	padding-bottom: 30px;
}



.projects {
	position: relative;
	padding: 90px 0 60px;
	background: #fff;
}

.default-portfolio-item {
	position: relative;
	padding-bottom: 30px;
}

.projects.light-bg {
	background: #fff;
}

.projects.light .post-filter li {
	color: #fff;
}


#myImg {
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
}

#myImg:hover {
	opacity: 0.7;
}

/* The Modal (background) */
.modal {
	display: none;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 1;
	/* Sit on top */
	padding-top: 100px;
	/* Location of the box */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.9);
	/* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
}

/* Caption of Modal Image */
#caption {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 700px;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;
}

/* Add Animation */
.modal-content,
#caption {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0)
	}

	to {
		-webkit-transform: scale(1)
	}
}

@keyframes zoom {
	from {
		transform: scale(0)
	}

	to {
		transform: scale(1)
	}
}

/* The Close Button */
.close {
	position: absolute;
	top: 15px;
	right: 35px;
	color: #f1f1f1;
	font-size: 40px;
	font-weight: bold;
	transition: 0.3s;
}

.close:hover,
.close:focus {
	color: #bbb;
	text-decoration: none;
	cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}

product-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0px 0px 0px 0px;
}

.product-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0px 0px 0px 0px;
}

.product-list .product-box {
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
}

.product-list .product-box .item,
.box-sh {
	background: transparent;
	border-radius: 10px;
	box-shadow: 0 0 5px rgb(0 0 0 / 25%);
	min-height: 250px;
}

.product-list .product-box:hover {
	transform: scale(1.05);
}

.product-list .product-box .item .cont {
	padding: 10px;
}

.product-list .product-box .item .product-title {
	font-size: 16px;
	font-family: 'Segoe UI';
}

.product-list .product-box .item .product-category {
	font-size: 14px;
	color: #2E2E2E;
}

.product-list .product-box .item .product-detail {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 13px;
	font-weight: 500;
	color: #2E2E2E;
}

.img-box {
	width: 100%;
}

.ownerName {
	color: #fff;

}

.accordion-button {
	background-color: #ed148b;
	color: white;

}

.accordion-button:not(.collapsed) {
	background-color: #ed148b;
	color: white;
	opacity: 0.7;
}

.form-check-input:checked[type=checkbox] {
	background-color: #ed148b;
	border: 1px solid #ed148b;
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }



  .fnc {
	/* you can add color names and their values here and then simply add classes like .m--blend-$colorName to .fnc-slide to apply specific color for mask blend mode */
}
 .fnc-slider {
	 overflow: hidden;
	 box-sizing: border-box;
	 position: relative;
	 height: 100vh;
}
 .fnc-slider *, .fnc-slider *:before, .fnc-slider *:after {
	 box-sizing: border-box;
}
 .fnc-slider__slides {
	 position: relative;
	 height: 100%;
	 transition: transform 1s 0.6666666667s;
}
 .fnc-slider .m--blend-dark .fnc-slide__inner {
	 background-color: #8a8a8a;
}
 .fnc-slider .m--blend-dark .fnc-slide__mask-inner {
	 background-color: #575757;
}
 .fnc-slider .m--navbg-dark {
	 background-color: #575757;
}
 .fnc-slider .m--blend-green .fnc-slide__inner {
	 background-color: #6d9b98;
}
 .fnc-slider .m--blend-green .fnc-slide__mask-inner {
	 background-color: #42605e;
}
 .fnc-slider .m--navbg-green {
	 background-color: #42605e;
}
 .fnc-slider .m--blend-red .fnc-slide__inner {
	 background-color: #ea2329;
}
 .fnc-slider .m--blend-red .fnc-slide__mask-inner {
	 background-color: #990e13;
}
 .fnc-slider .m--navbg-red {
	 background-color: #990e13;
}
 .fnc-slider .m--blend-blue .fnc-slide__inner {
	 background-color: #59aecb;
}
 .fnc-slider .m--blend-blue .fnc-slide__mask-inner {
	 background-color: #2d7791;
}
 .fnc-slider .m--navbg-blue {
	 background-color: #2d7791;
}
.fnc-slider .m--navbg-pink {
	background-color:#ed148b;
}
 .fnc-slide {
	 overflow: hidden;
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 transform: translate3d(0, 0, 0);
}
 .fnc-slide.m--before-sliding {
	 z-index: 2 !important;
	 transform: translate3d(100%, 0, 0);
}
 .fnc-slide.m--active-slide {
	 z-index: 1;
	 transition: transform 1s 0.6666666667s ease-in-out;
	 transform: translate3d(0, 0, 0);
}
 .fnc-slide__inner {
	 position: relative;
	 height: 100%;
	 background-size: cover;
	 background-position: center center;
	 transform: translate3d(0, 0, 0);
}
 .m--global-blending-active .fnc-slide__inner, .m--blend-bg-active .fnc-slide__inner {
	 background-blend-mode: luminosity;
}
 .m--before-sliding .fnc-slide__inner {
	 transform: translate3d(-100%, 0, 0);
}
 .m--active-slide .fnc-slide__inner {
	 transition: transform 1s 0.6666666667s ease-in-out;
	 transform: translate3d(0, 0, 0);
}
 .fnc-slide__mask {
	 overflow: hidden;
	 z-index: 1;
	 position: absolute;
	 right: 60%;
	 top: 15%;
	 width: 50.25vh;
	 height: 67vh;
	 margin-right: -90px;
	 clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0, 6vh 0, 6vh 61vh, 44vh 61vh, 44vh 6vh, 6vh 6vh);
	 transform-origin: 50% 0;
	 transition-timing-function: ease-in-out;
}
 .m--before-sliding .fnc-slide__mask {
	 transform: rotate(-10deg) translate3d(200px, 0, 0);
	 opacity: 0;
}
 .m--active-slide .fnc-slide__mask {
	 transition: transform 0.7s 1.2222222222s, opacity 0.35s 1.2222222222s;
	 transform: translate3d(0, 0, 0);
	 opacity: 1;
}
 .m--previous-slide .fnc-slide__mask {
	 transition: transform 0.7s 0.3333333333s, opacity 0.35s 0.6833333333s;
	 transform: rotate(10deg) translate3d(-200px, 0, 0);
	 opacity: 0;
}
 .fnc-slide__mask-inner {
	 z-index: -1;
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 width: 100vw;
	 height: 100vh;
	 margin-left: -50vw;
	 margin-top: -50vh;
	 background-size: cover;
	 background-position: center center;
	 background-blend-mode: luminosity;
	 transform-origin: 50% 16.5vh;
	 transition-timing-function: ease-in-out;
}
 .m--before-sliding .fnc-slide__mask-inner {
	 transform: translateY(0) rotate(10deg) translateX(-200px) translateZ(0);
}
 .m--active-slide .fnc-slide__mask-inner {
	 transition: transform 0.7s 1.2222222222s;
	 transform: translateX(0);
}
 .m--previous-slide .fnc-slide__mask-inner {
	 transition: transform 0.7s 0.3333333333s;
	 transform: translateY(0) rotate(-10deg) translateX(200px) translateZ(0);
}
 .fnc-slide__content {
	 z-index: 2;
	 position: absolute;
	 right: 0;
	 top: 70%;
}
 .fnc-slide__heading {
	 margin-bottom: 10px;
	 text-transform: uppercase;
	 background-color: #0000007d;
    padding: 10px;
}
 .fnc-slide__heading-line {
	 overflow: hidden;
	 position: relative;
	 padding-right: 20px;
	 font-size: 60px;
	 color: #fff;
	 word-spacing: 10px;
}
 .fnc-slide__heading-line:nth-child(2) {
	 padding-left: 30px;
}
 .m--before-sliding .fnc-slide__heading-line {
	 transform: translateY(100%);
}
 .m--active-slide .fnc-slide__heading-line {
	 transition: transform 1.5s 1s;
	 transform: translateY(0);
}
 .m--previous-slide .fnc-slide__heading-line {
	 transition: transform 1.5s;
	 transform: translateY(-100%);
}
 .fnc-slide__heading-line span {
	 display: block;
}
 .m--before-sliding .fnc-slide__heading-line span {
	 transform: translateY(-100%);
}
 .m--active-slide .fnc-slide__heading-line span {
	 transition: transform 1.5s 1s;
	 transform: translateY(0);
}
 .m--previous-slide .fnc-slide__heading-line span {
	 transition: transform 1.5s;
	 transform: translateY(100%);
}
 .fnc-slide__action-btn {
	 position: relative;
	 margin-left: 200px;
	 padding: 5px 15px;
	 font-size: 20px;
	 line-height: 1;
	 color: transparent;
	 border: none;
	 text-transform: uppercase;
	 background: transparent;
	 cursor: pointer;
	 text-align: center;
	 outline: none;
}
 .fnc-slide__action-btn span {
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 perspective: 1000px;
	 transform-style: preserve-3d;
	 transition: transform 0.3s;
	 transform-origin: 50% 0;
	 line-height: 30px;
	 color: #fff;
}
 .fnc-slide__action-btn span:before {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 border: 2px solid #fff;
	 border-top: none;
	 border-bottom: none;
}
 .fnc-slide__action-btn span:after {
	 content: attr(data-text);
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 line-height: 30px;
	 background: #1f2833;
	 opacity: 0;
	 transform-origin: 50% 0;
	 transform: translateY(100%) rotateX(-90deg);
	 transition: opacity 0.15s 0.15s;
}
 .fnc-slide__action-btn:hover span {
	 transform: rotateX(90deg);
}
 .fnc-slide__action-btn:hover span:after {
	 opacity: 1;
	 transition: opacity 0.15s;
}
 .fnc-nav {
	 z-index: 5;
	 position: absolute;
	 right: 0;
	 top: 200px;
}
 .fnc-nav__bgs {
	 z-index: -1;
	 overflow: hidden;
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
}
 .fnc-nav__bg {
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
}
 .fnc-nav__bg.m--nav-bg-before {
	 z-index: 2 !important;
	 transform: translateX(100%);
}
 .fnc-nav__bg.m--active-nav-bg {
	 z-index: 1;
	 transition: transform 1s 0.6666666667s;
	 transform: translateX(0);
}
 .fnc-nav__controls {
	 font-size: 0;
}
 .fnc-nav__control {
	 overflow: hidden;
	 position: relative;
	 display: block;
	 vertical-align: top;
	 width: 150px;
	 height: 50px;
	 font-size: 14px;
	 color: #fff;
	 text-transform: uppercase;
	 background: transparent;
	 border: none;
	 outline: none;
	 cursor: pointer;
	 transition: background-color 0.5s;
}
 .fnc-nav__control.m--active-control {
	 background: #1f2833;
}
 .fnc-nav__control-progress {
	 position: absolute;
	 left: 0;
	 bottom: 0;
	 width: 100%;
	 height: 2px;
	 background: #fff;
	 transform-origin: 0 50%;
	 transform: scaleX(0);
	 transition-timing-function: linear !important;
}
 .m--with-autosliding .m--active-control .fnc-nav__control-progress {
	 transform: scaleX(1);
}
 .m--prev-control .fnc-nav__control-progress {
	 transform: translateX(100%);
	 transition: transform 0.5s !important;
}
 .m--reset-progress .fnc-nav__control-progress {
	 transform: scaleX(0);
	 transition: transform 0s 0s !important;
}
 .m--autosliding-blocked .fnc-nav__control-progress {
	 transition: all 0s 0s !important;
	 transform: scaleX(0) !important;
}
/* NOT PART OF COMMON SLIDER STYLES */
 body {
	 margin: 0;
}
 .demo-cont {
	 overflow: hidden;
	 position: relative;
	 height: 100vh;
	 perspective: 1500px;
	 background: #000;
}
 .demo-cont__credits {
	 box-sizing: border-box;
	 overflow-y: auto;
	 z-index: 1;
	 position: absolute;
	 right: 0;
	 top: 0;
	 width: 400px;
	 height: 100%;
	 padding: 20px 10px 30px;
	 background: #303030;
	 font-family: "Open Sans", Helvetica, Arial, sans-serif;
	 color: #fff;
	 text-align: center;
	 transition: transform 0.7s;
	 transform: translate3d(100%, 0, 0) rotateY(-45deg);
	 will-change: transform;
}
 .credits-active .demo-cont__credits {
	 transition: transform 0.7s 0.2333333333s;
	 transform: translate3d(0, 0, 0);
}
 .demo-cont__credits *, .demo-cont__credits *:before, .demo-cont__credits *:after {
	 box-sizing: border-box;
}
 .demo-cont__credits-close {
	 position: absolute;
	 right: 20px;
	 top: 20px;
	 width: 28px;
	 height: 28px;
	 cursor: pointer;
}
 .demo-cont__credits-close:before, .demo-cont__credits-close:after {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 50%;
	 width: 100%;
	 height: 2px;
	 margin-top: -1px;
	 background: #fff;
}
 .demo-cont__credits-close:before {
	 transform: rotate(45deg);
}
 .demo-cont__credits-close:after {
	 transform: rotate(-45deg);
}
 .demo-cont__credits-heading {
	 text-transform: uppercase;
	 font-size: 40px;
	 margin-bottom: 20px;
}
 .demo-cont__credits-img {
	 display: block;
	 width: 60%;
	 margin: 0 auto 30px;
	 border-radius: 10px;
}
 .demo-cont__credits-name {
	 margin-bottom: 20px;
	 font-size: 30px;
}
 .demo-cont__credits-link {
	 display: block;
	 margin-bottom: 10px;
	 font-size: 24px;
	 color: #fff;
}
 .demo-cont__credits-blend {
	 font-size: 30px;
	 margin-bottom: 10px;
}
 .example-slider {
	 z-index: 2;
	 transform: translate3d(0, 0, 0);
	 transition: transform 0.7s;
}
 .credits-active .example-slider {
	 transform: translate3d(-400px, 0, 0) rotateY(10deg) scale(0.9);
}
 .example-slider .fnc-slide-1 .fnc-slide__inner, .example-slider .fnc-slide-1 .fnc-slide__mask-inner {
	 background-image: url("../src/img/floor.jpg");
}
 .example-slider .fnc-slide-2 .fnc-slide__inner, .example-slider .fnc-slide-2 .fnc-slide__mask-inner {
	 background-image: url("../src/img/porcelian.jpg");
}
 .example-slider .fnc-slide-3 .fnc-slide__inner, .example-slider .fnc-slide-3 .fnc-slide__mask-inner {
	 background-image: url("../src/img/vitrified.jpg");
}
 .example-slider .fnc-slide-3 .fnc-slide__inner:before {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 background: rgba(255, 255, 255, 0.1);
}
 .example-slider .fnc-slide-4 .fnc-slide__inner, .example-slider .fnc-slide-4 .fnc-slide__mask-inner {
	 background-image: url("../src/img/wall.jpg");
}
.example-slider .fnc-slide-5 .fnc-slide__inner, .example-slider .fnc-slide-5 .fnc-slide__mask-inner {
	background-image: url("../src/img/sanitary.jpg");
}
 .example-slider .fnc-slide-4 .fnc-slide__inner:before {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 background: rgba(0, 0, 0, 0.2);
}
 .example-slider .fnc-slide__heading, .example-slider .fnc-slide__action-btn, .example-slider .fnc-nav__control {
	 font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
/* COLORFUL SWITCH STYLES ORIGINAL DEMO - https://codepen.io/suez/pen/WQjwOb */
 .colorful-switch {
	 position: relative;
	 width: 180px;
	 height: 77.1428571429px;
	 margin: 0 auto;
	 border-radius: 32.1428571429px;
	 background: #cfcfcf;
}
 .colorful-switch:before {
	 content: "";
	 z-index: -1;
	 position: absolute;
	 left: -5px;
	 top: -5px;
	 width: 190px;
	 height: 87.1428571429px;
	 border-radius: 37.1428571429px;
	 background: #314239;
	 transition: background-color 0.3s;
}
 .colorful-switch:hover:before {
	 background: #4c735f;
}
 .colorful-switch__checkbox {
	 z-index: -10;
	 position: absolute;
	 left: 0;
	 top: 0;
	 opacity: 0;
}
 .colorful-switch__label {
	 z-index: 1;
	 overflow: hidden;
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 100%;
	 height: 100%;
	 border-radius: 32.1428571429px;
	 cursor: pointer;
}
 .colorful-switch__bg {
	 position: absolute;
	 left: 0;
	 top: 0;
	 width: 540px;
	 height: 100%;
	 background: linear-gradient(90deg, #14dcd6 0, #10e7bd 180px, #ef9c29 360px, #e76339 100%);
	 transition: transform 0.5s;
	 transform: translate3d(-360px, 0, 0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__bg {
	 transform: translate3d(0, 0, 0);
}
 .colorful-switch__dot {
	 position: absolute;
	 left: 131.1428571429px;
	 top: 50%;
	 width: 5.1428571429px;
	 height: 5.1428571429px;
	 margin-left: -2.5714285714px;
	 margin-top: -2.5714285714px;
	 border-radius: 50%;
	 background: #fff;
	 transition: transform 0.5s;
	 transform: translate3d(0, 0, 0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__dot {
	 transform: translate3d(-80.3571428571px, 0, 0);
}
 .colorful-switch__on {
	 position: absolute;
	 left: 104.1428571429px;
	 top: 22.5px;
	 width: 19.2857142857px;
	 height: 36px;
	 transition: transform 0.5s;
	 transform: translate3d(0, 0, 0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__on {
	 transform: translate3d(-80.3571428571px, 0, 0);
}
 .colorful-switch__on__inner {
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 transition: transform 0.25s 0s cubic-bezier(0.52, -0.96, 0.51, 1.28);
	 transform-origin: 100% 50%;
	 transform: rotate(45deg) scale(0) translateZ(0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__on__inner {
	 transition: transform 0.25s 0.25s cubic-bezier(0.67, -0.16, 0.47, 1.61);
	 transform: rotate(45deg) scale(1) translateZ(0);
}
 .colorful-switch__on__inner:before, .colorful-switch__on__inner:after {
	 content: "";
	 position: absolute;
	 border-radius: 2.5714285714px;
	 background: #fff;
}
 .colorful-switch__on__inner:before {
	 left: 0;
	 bottom: 0;
	 width: 100%;
	 height: 6.1428571429px;
}
 .colorful-switch__on__inner:after {
	 right: 0;
	 top: 0;
	 width: 6.1428571429px;
	 height: 100%;
}
 .colorful-switch__off {
	 position: absolute;
	 left: 131.1428571429px;
	 top: 50%;
	 width: 41.1428571429px;
	 height: 41.1428571429px;
	 margin-left: -20.5714285714px;
	 margin-top: -20.5714285714px;
	 transition: transform 0.5s;
	 transform: translate3d(0, 0, 0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__off {
	 transform: translate3d(-80.3571428571px, 0, 0);
}
 .colorful-switch__off:before, .colorful-switch__off:after {
	 content: "";
	 position: absolute;
	 left: 0;
	 top: 50%;
	 width: 100%;
	 height: 5.1428571429px;
	 margin-top: -2.5714285714px;
	 border-radius: 2.5714285714px;
	 background: #fff;
	 transition: transform 0.25s 0.25s;
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__off:before, .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__off:after {
	 transition-delay: 0s;
}
 .colorful-switch__off:before {
	 transform: rotate(45deg) scaleX(1) translateZ(0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__off:before {
	 transform: rotate(45deg) scaleX(0) translateZ(0);
}
 .colorful-switch__off:after {
	 transition-timing-function: cubic-bezier(0.67, -0.16, 0.47, 1.61);
	 transform: rotate(-45deg) scaleX(1) translateZ(0);
}
 .colorful-switch__checkbox:checked ~ .colorful-switch__label .colorful-switch__off:after {
	 transition-timing-function: ease;
	 transform: rotate(-45deg) scaleX(0) translateZ(0);
}
 